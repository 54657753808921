import { SvgIconMemo, SvgIconProps } from './svg-icon';

export function ChevronDownIcon({ viewBox = '0 0 32 32', ...restProps }: SvgIconProps) {
  return (
    <SvgIconMemo viewBox={viewBox} {...restProps}>
      <path
        d="M16 23C17.12 23 18.16 22.57 18.95 21.78L29 11.77L27.23 10L17.18 20.01C16.55 20.64 15.46 20.64 14.83 20.01L4.77 10L3 11.77L13.05 21.78C13.84 22.57 14.89 23 16 23Z"
        fill="currentColor"
      />
    </SvgIconMemo>
  );
}
