import { memo } from 'react';
import { AnchorButtonMemo } from '../buttons/button/button';
import { CardListHeaderMemo } from '../cards/card-list-header/card-list-header';
import { ARROW_NAV_TARGET_CLASS } from '../../utils/nav-system';
import { SIDE_PANEL_ANCHOR_CLASS } from '../../constants';
import styles from './ray-callout.module.css';

export function RayCallout() {
  return (
    <div className={styles.root}>
      <CardListHeaderMemo title="From the Ray Team" />
      <div className={styles.content}>
        <h2>
          Have a game suggestion?
          <br />
          Share it with us!
        </h2>
        <div>
          <AnchorButtonMemo
            theme="Discord"
            className={`${ARROW_NAV_TARGET_CLASS} ${SIDE_PANEL_ANCHOR_CLASS}`}
            href="https://discord.gg/sPgqXjHZJU"
            rel="noopener noreferrer"
            target="_blank"
          >
            Join Our Discord
          </AnchorButtonMemo>
        </div>
      </div>
    </div>
  );
}

export const RayCalloutMemo = memo(RayCallout);
