import { memo, useRef, useEffect } from 'react';
import { AnchorButtonMemo } from '../../buttons/button/button';
import { DetailLinkMemo } from '../../detail-link/detail-link';
import { AnchorTagMemo } from '../../tag/tag';
import { ChevronDownIcon } from '../../svg-icons/chevron-down-icon';
import { useStore } from '../../../providers/store-provider';
import { ARROW_NAV_RECT_ATTRIBUTE, navSystem } from '../../../utils/nav-system';
import styles from './popup-card-popover.module.css';

export type PopupCardPopoverProps = {
  // App data
  id: string;
  type: string;
  name: string;
  launchUrl: string;
  imageUrl: string;
  videoUrl?: string;
  shortDescription?: string;
  categories?: { id: string; name: string }[];
  // Other props
  cardId: string;
  isCardActive: boolean;
  isVisible: boolean;
  isInfoModalVisible: boolean;
  showInfoModal: () => void;
};

export const PopupCardPopover = ({
  cardId,
  isCardActive,
  isVisible,
  isInfoModalVisible,
  showInfoModal,
  ...item
}: PopupCardPopoverProps) => {
  const launchActionRef = useRef<HTMLAnchorElement | null>(null);
  const addRecentlyPlayedId = useStore((state) => state.addRecentlyPlayedId);
  const isRendered = isCardActive || isVisible || isInfoModalVisible;

  // Focus the launch action when the popover is rendered.
  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (
      isRendered &&
      launchActionRef.current &&
      launchActionRef.current !== document.activeElement &&
      navSystem.getNavDevice() === 'keyboard'
    ) {
      launchActionRef.current.focus({ preventScroll: true });
    }
  }, [isRendered]);

  // A quick note about the isCardActive and isVisible props. isVisible is set
  // to true a tick later than isCardActive which is important to make the
  // show transitions work. On first render there is no `styles.isVisible`
  // class in the root element so the element's will render in the hidden state.
  // Then the `styles.isVisible` class is added in the next tick which triggers
  // the show transition.

  return isRendered ? (
    <div
      className={`${styles.root} ${isCardActive ? styles.isCardActive : ''} ${isVisible ? styles.isVisible : ''}`}
    >
      <div className={styles.container}>
        <div className={styles.bg}></div>
        <div className={styles.content}>
          <div className={styles.title}>{item.name}</div>
          {!!item.shortDescription && (
            <div className={styles.description}>{item.shortDescription}</div>
          )}
          {!!item.categories?.length && (
            <div className={styles.tags}>
              {item.categories.map((category) => (
                <AnchorTagMemo
                  key={category.id}
                  name={category.name}
                  href={`/category/${category.id}`}
                  tabIndex={-1}
                  {...{
                    [ARROW_NAV_RECT_ATTRIBUTE]: `[data-card-id="${cardId}"]`,
                  }}
                />
              ))}
            </div>
          )}
          <div className={styles.actions}>
            <AnchorButtonMemo
              ref={launchActionRef}
              className={styles.launchAction}
              theme="Primary"
              href={item.launchUrl}
              target="_blank"
              rel="noreferrer"
              onClick={() => addRecentlyPlayedId(item.id)}
              {...{
                [ARROW_NAV_RECT_ATTRIBUTE]: `[data-card-id="${cardId}"]`,
              }}
            >
              {item.type === 'game' ? 'Play' : 'Launch'}
            </AnchorButtonMemo>
            <DetailLinkMemo
              className={styles.infoAction}
              theme="SecondaryLight"
              itemType={item.type}
              itemId={item.id}
              onClick={showInfoModal}
              {...{
                [ARROW_NAV_RECT_ATTRIBUTE]: `[data-card-id="${cardId}"]`,
              }}
            >
              <ChevronDownIcon />
            </DetailLinkMemo>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export const PopupCardPopoverMemo = memo(PopupCardPopover);
